import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    nb_NO: {
      translation: {
        welcome_title: 'Velkommen til Carrot',
        company_code: 'Bedriftskode',
        log_in: 'Logg inn',
        popover_title: 'Hva er bedriftskode?',
        popover_text1: 'Bedriftskoden identifiserer bedriften du jobber i. Ved å logge inn med denne, får du registrert kasting av avfall på riktig bedrift.',
        popover_text2: 'Bedriftskoden består av ni bokstaver.',
        popover_text3: 'Den har blitt sendt på mail, ofte til butikksjef/daglig leder. Mailen kommer fra support@carrot.tech. (Husk å sjekke spam-mappen, den kan ha havnet der.)',
        popover_text4: 'Brukere som allerede er innlogget i appen, kan finne bedriftskoden under «Profil», slik at denne kan deles med kollegaer.',
        popover_text5: 'Ved problemer, kontakt',
        error_unknown_company_code1: `Ukjent bedriftskode`,
        error_unknown_company_code2: `Prøv å skrive inn igjen`,
        error_coverage1: 'Du mangler visst dekning',
        error_coverage2: 'Sørg for dekning og prøv igjen.',
        error_system1: 'Systemfeil',
        error_system2: 'Ta kontakt med support@carrot.tech.',
      },
    },
    en_US: {
      translation: {
        welcome_title: 'Welcome to Carrot',
        company_code: 'Company code',
        log_in: 'Login',
        popover_title: 'What is the company code?',
        popover_text1: 'The company code identifies the company you work at. By logging in with this code, we will know you are the one throwing.',
        popover_text2: 'The company code consists of nine letters.',
        popover_text3:
          'The company code has been sent via email, often to the store manager/general manager. The mail is sent by support@carrot.tech. (Remember to check the spam folder, as it might have ended up there.)',
        popover_text4: 'When already logged in to the app, you can find the company code under «Profile», so that it can be shared with colleagues.',
        popover_text5: 'In case of problems, contact',
        error_unknown_company_code1: `Unknown company code`,
        error_unknown_company_code2: `Try again`,
        error_coverage1: 'Seems you have no coverage!',
        error_coverage2: 'Make sure you have coverage and try again.',
        error_system1: 'System error',
        error_system2: 'Please contact support@carrot.tech.',
      },
    },
  },
  fallbackLng: 'nb_NO',
  interpolation: {
    escapeValue: false,
  },
})
